.index-page {
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #fff;
    margin-top: 20px;
  }

  &__qr-link {
    margin-top: 20px;
  }
}