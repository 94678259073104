.audio-player-page {
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #fff;
    margin-top: 20px;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: var(--gray-light-color);
    max-height: 63px;
    overflow: hidden;
    position: relative;
    margin-top: 10px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 41px;
      background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
    }
  }

  &__qr-link {
    margin-top: 18px;
  }

  &__show-more {
    margin-top: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #fff;
  }

  &__player {
    margin-top: 33px;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__slider-wrap {
    position: relative;
    padding-bottom: 37px;
    margin: 0 calc(var(--grid-spacer) * -1);
  }

  .swiper-pagination-bullet {
    background: #fff;
  }
}

@include respond-up('large') {
  .audio-player-page {

  }
}

@include respond-up('medium') {
  .audio-player-page {

  }
}

@include respond('medium') {
  .audio-player-page {

  }
}

@include respond-down('medium') {
  .audio-player-page {

  }
}

@include respond-down('small') {
  .audio-player-page {

  }
}