.description-modal {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--primary-color);

  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;

  &._opened {
    opacity: 1;
    pointer-events: auto;
  }

  &__inner {
    padding: 90px 0 40px;
    overflow-y: scroll;
    height: 100%;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 4px 4px var(--color-background);
      border: solid 3px var(--t);
      border-radius: 4px;
      background-color: var(--gray-light-color);
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #fff;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: var(--gray-light-color);
    margin-top: 15px;
  }

  &__closer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 51px;
    height: 51px;
    border-radius: 100%;
    background-color: var(--dark-gray-color);
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@include respond-up('large') {
  .description-modal {

  }
}

@include respond-up('medium') {
  .description-modal {

  }
}

@include respond('medium') {
  .description-modal {

  }
}

@include respond-down('medium') {
  .description-modal {

  }
}

@include respond-down('small') {
  .description-modal {

  }
}