:root {
  --default-input-height: 67px;
  --errors-color: var(--alert-color, #b50000)
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

  #{$inputs}, textarea, select {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset;
      -webkit-text-fill-color: var(--text-color);
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-timing-function);
    }
  }

  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: var(--errors-color);
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 1px solid #c1c1c1;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  .button, button {
    padding: 0;
    margin: 0;
    text-align: center;
    transition: var(--default-transition);
    display: inline-block;
    border: none;
    background: var(--primary-color);
    font-size: 16px;
    cursor: pointer;

    &.height {
      height: var(--default-input-height);
    }
  }
}