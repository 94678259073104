.button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  &_primary {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-gray-color);
    border-radius: 100px;
    padding: 26px;
  }

  &_secondary {
    .button__title {
      padding-bottom: 1px;
      border-bottom: 1px solid var(--gray-border-color);
    }
  }

  &__title {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #fff;
  }

  &__icon {
    display: flex;
    height: max-content;
    width: max-content;
  }
}