.audio-player {
  &__rewind {
    display: flex;
    padding: 15px;

    &_forward {
      .audio-player__icon_rewind {
        transform: scale(-1, 1) translate3d(50%, 0, 0);
      }
    }

    &._mousedown {
      .audio-player__rewind-inner {
        transform: scale(0.89);
      }
    }
  }

  &__rewind-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: transform .1s;
  }

  &__rewind-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #fff;
  }

  &__play-pause {
    display: flex;
    padding: 15px;

    &._mousedown {
      .audio-player__play-pause-inner {
        transform: scale(0.89);
      }
    }
  }

  &__play-pause-inner {
    display: flex;
    width: 76px;
    height: 76px;
    position: relative;
    border-radius: 100%;
    transition: transform .1s;
  }

  &__icon {
    display: flex;
    width: max-content;
    height: max-content;
    position: absolute;

    &_play,
    &_pause {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &_play {
      opacity: 1;
    }

    &_pause {
      opacity: 0;
    }

    &_rewind {
      top: 0;
      left: 50%;
      transform: translate3d(-49%, 0, 0);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__timeline-wrap {
    margin-top: 10px;
  }

  &__range {
    position: relative;
    display: flex;
    align-items: center;
    height: 13px;
    width: 100%;

    input[type="range"] {
      margin: 0;
      padding: 0;
      -webkit-appearance: none;
      cursor: pointer;
      width: 100%;
      height: 4px;
      background: var(--gray-border-color);

      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
        cursor: pointer;
      }

      &::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
        cursor: pointer;
      }

      &::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
        cursor: pointer;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 13px;
        width: 13px;
        border-radius: 100%;
        background: #fff;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 13px;
        width: 13px;
        border-radius: 100%;
        background: #fff;
        cursor: ew-resize;
      }

      &::-ms-thumb {
        -webkit-appearance: none;
        height: 13px;
        width: 13px;
        border-radius: 100%;
        background: #fff;
        cursor: ew-resize;
      }
    }
  }

  &__range-progress {
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 4px;
    transform: translate3d(0, -50%, 0);
    background-color: #fff;
  }

  &__timeline {
    display: flex;
    align-items: center;
    height: 13px;
    position: relative;
  }

  &__timeline-track {
    position: relative;
    height: 4px;
    width: 100%;
  }

  &__duration-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }

  &__current,
  &__left {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: var(--gray-color);
  }

  &._play {
    .audio-player {
      &__icon {
        &_play {
          opacity: 0;
        }

        &_pause {
          opacity: 1;
        }
      }
    }
  }
}

@include respond-up('large') {
  .audio-player {

  }
}

@include respond-up('medium') {
  .audio-player {

  }
}

@include respond('medium') {
  .audio-player {

  }
}

@include respond-down('medium') {
  .audio-player {

  }
}

@include respond-down('small') {
  .audio-player {

  }
}