.qr-scanner {
  &__video-popup {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);

    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    background-color: var(--primary-color);

    &._opened {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__video {
    width: 100%;
    height: 100%;
  }

  &__video-popup-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 51px;
    height: 51px;
    border-radius: 100%;
    background-color: var(--dark-gray-color);
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__button-title {
    margin-left: 17px;
  }
}

@include respond-up('large') {
  .qr-scanner {

  }
}

@include respond-up('medium') {
  .qr-scanner {

  }
}

@include respond('medium') {
  .qr-scanner {

  }
}

@include respond-down('medium') {
  .qr-scanner {

  }
}

@include respond-down('small') {
  .qr-scanner {

  }
}